/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Button, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import { CiCircleCheck } from 'react-icons/ci';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineInsertPageBreak } from 'react-icons/md';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, useCompanies } from '../../../../hooks/useCompanies';
import { IClient } from '../../../../hooks/useClients';
import { getFormattedLabelString } from '../../../../utils/FormatFunctions';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';
import { Paginator } from '../../../../components/paginator';

export function ModulesHomeIntegrationSequenceBreakPage() {
  const { getCompanies, getQuebras, getQuebrasTotais, getCompaniesFilters } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => setShowFilters(prev => !prev);

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      companyId: '',
      date_start: moment().subtract(6, 'days').format('YYYY-MM-DD'), // Convertendo para string
      date_end: moment().format('YYYY-MM-DD'), // Convertendo para string
    }),
  );
  const dateStart = filters.get('date_start');
  const dateEnd = filters.get('date_end');

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values =>
      setFilters(
        createSearchParams({
          ...Object.fromEntries(filters),
          search: values.search,
          id: values.companies?.external_id || '',
          cidade: values.cidade?.value ?? '',
          uf: values.uf?.value ?? '',
          grupo_de_empresas: values.grupo_de_empresas?.value ?? '',
          tags: values.tags?.value ?? '',
          comercial: values.comercial?.value ?? '',
          contabil: values.contabil?.value ?? '',
          financeiro: values.financeiro?.value ?? '',
          contas_a_pagar: values.contas_a_pagar?.value ?? '',
          encerramento_anual: values.encerramento_anual?.value ?? '',
          fiscal: values.fiscal?.value ?? '',
          imposto_de_renda: values.imposto_de_renda?.value ?? '',
          integracao_contabil: values.integracao_contabil?.value ?? '',
          integracao_fiscal: values.integracao_fiscal?.value ?? '',
          pessoal: values.pessoal?.value ?? '',
          relacionamento_com_cliente: values.relacionamento_com_cliente?.value ?? '',
          societario: values.societario?.value ?? '',
          page: '1',
        }),
      ),
    500,
  );

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const queryKey = ['getQuebras', Object.fromEntries([...Array.from(filters)])];

  const queryKeyQuebrasTotatais = [
    'getQuebrasTotais',
    {
      date_start: filters.get('date_start'),
      date_end: filters.get('date_end'),
    },
  ];

  const { data: dataCompanies, isFetching: isFetchingCompany } = useQuery<IData<ICompany[]>>({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataCompaniesQuebras, isFetching: isFetchingCompanyQuebras } = useQuery<
    IData<ICompany[]>
  >({
    queryKey,
    queryFn: getQuebras,
  });

  const { data: quebrasTotais, isFetching: isFetchingQuebrasTotais } = useQuery<any>({
    queryKey: queryKeyQuebrasTotatais,
    queryFn: getQuebrasTotais,
  });

  const { items: Quebras, ...pagination } = dataCompaniesQuebras?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: companies } = dataCompanies?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <ReturnButton label="Voltar para página anterior" previousPage="modulesHome/integration" />
      <div className="flex flex-col items-center gap-3 p-5 lg:flex-row">
        <div className="h-full">
          <div className="flex items-center justify-center rounded-full bg-[#DFF3FF] p-3">
            <MdOutlineInsertPageBreak color="#008CCC" className="size-12" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-1">
          <Text fontWeight="bold" fontSize="16px">
            Quebra de Sequência
          </Text>
        </div>
      </div>

      <div className="mb-5 grid grid-cols-1 gap-3 lg:grid-cols-4">
        {isFetchingQuebrasTotais ? (
          <div className="flex flex-col items-center gap-4">
            <Spinner size="lg" color="primary" />
            <span className="text-xl font-semibold">Carregando...</span>
          </div>
        ) : (
          <>
            <Flex
              className={`flex cursor-pointer items-center gap-3 rounded-3xl p-5 ${
                filters.get('situacao') === 'ok' ? 'bg-[#DCFAF8]' : 'bg-body'
              }`}
              onClick={() => {
                const newFilters = new URLSearchParams(Object.fromEntries(filters));
                if (filters.get('situacao') === 'ok') {
                  newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                } else {
                  newFilters.set('situacao', 'ok'); // Aplica o filtro
                }
                setFilters(newFilters);
              }}
            >
              <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#DCFAF8] p-3">
                <CiCircleCheck color="#16DBCC" className="size-7" />
              </div>

              <div className="flex w-full flex-col ">
                <Text fontSize="14px" color="#718EBF">
                  Sem quebra de sequência
                </Text>
                <Text fontSize="18px" fontWeight="bold">
                  {quebrasTotais?.sem_quebras}
                </Text>
              </div>
            </Flex>
            <Flex
              className={`flex cursor-pointer items-center gap-3 rounded-3xl p-5 ${
                filters.get('situacao') === 'erro' ? 'bg-[#FFE0EB]' : 'bg-body'
              }`}
              onClick={() => {
                const newFilters = new URLSearchParams(Object.fromEntries(filters));
                if (filters.get('situacao') === 'erro') {
                  newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                } else {
                  newFilters.set('situacao', 'erro'); // Aplica o filtro
                }
                setFilters(newFilters);
              }}
            >
              <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFE0EB] p-3">
                <IoCloseCircleOutline color="#FF82AC" className="size-7" />
              </div>

              <div className="flex w-full flex-col ">
                <Text fontSize="14px" color="#718EBF">
                  Com quebra de sequência
                </Text>
                <Text fontSize="18px" fontWeight="bold">
                  {quebrasTotais?.quebras}
                </Text>
              </div>
            </Flex>
          </>
        )}
      </div>
      <HStack justify="flex-end" mt={8} mb={8}>
        <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
      </HStack>
      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex w-fit flex-row gap-4">
                <InputForm
                  name="search"
                  placeholder="Buscar por nome, cnpj, razão social"
                  boxShadow="0px 2px 4px #1E293B0D"
                  controlMb={0}
                />
                <Button
                  onClick={toggleFilters}
                  rightIcon={
                    showFilters ? (
                      <MdKeyboardArrowUp size="25px" />
                    ) : (
                      <MdKeyboardArrowDown size="25px" />
                    )
                  }
                >
                  {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                </Button>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400 shadow-sm">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompany}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingCompanyQuebras ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : Quebras?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters.get('search') && Quebras?.length === 0 && !isFetchingCompanyQuebras ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {Quebras?.map(item => (
                <div className="flex gap-4 rounded-lg border border-border bg-body p-4 shadow-lg lg:flex-col">
                  <div className="grid grid-cols-1 gap-9 lg:grid-cols-3">
                    <div className="flex flex-col gap-2 ">
                      <span className="text-base font-bold">{item?.nome}</span>
                      <span className="text-sm  text-[#718EBF]">{item?.razao_social}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NFC-e com quebra</span>
                        <div>
                          {item?.nf_quebradas?.nfce === 0 ? (
                            <CiCircleCheck color="#16DBCC" className="size-7" />
                          ) : (
                            <div className="flex items-center gap-2">
                              <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                              <Text className="text-slate-500">{item?.nf_quebradas?.nfce}</Text>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NF-e com quebras</span>
                        <div>
                          {item?.nf_quebradas?.nfe === 0 ? (
                            <CiCircleCheck color="#16DBCC" className="size-7" />
                          ) : (
                            <div className="flex items-center gap-2">
                              <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                              <Text className="text-slate-500">{item?.nf_quebradas?.nfe}</Text>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">NFS-e com quebras</span>
                        <div>
                          {item?.nf_quebradas?.nfse === 0 ? (
                            <CiCircleCheck color="#16DBCC" className="size-7" />
                          ) : (
                            <div className="flex items-center gap-2">
                              <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                              <Text className="text-slate-500">{item?.nf_quebradas?.nfse}</Text>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 pt-3 lg:items-start lg:pt-0">
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1">
                          <span className="text-base font-bold">Total de</span>
                          <span className="text-base font-bold">XML's</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <Text className="text-slate-500">
                            {(item?.quebras ?? 0) + (item?.sem_quebras ?? 0)}
                          </Text>
                        </div>
                      </div>
                      <div className="col-span-1 lg:pt-5">
                        <Link
                          to={{
                            pathname: `/breaks/${item.external_id}`,
                            search: createSearchParams({
                              date_start:
                                dateStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              date_end: dateEnd || moment().format('YYYY-MM-DD'),
                            }).toString(),
                          }}
                        >
                          <Button
                            variant="outline"
                            h="fit-content"
                            w="100%"
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="full"
                            border="2px solid"
                            borderColor="#718EBF"
                            bg="white"
                            p={2}
                          >
                            <Text className="text-sm" color="#718EBF">
                              Ver quebras
                            </Text>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} />
            </div>
          )}
        </div>
      </FormBox>
    </div>
  );
}
